import Decimal from 'decimal.js';
import logger from 'logger';
import { BillingPlans } from 'types';

import { PlanPrice as PlanPriceApi } from '~/data';

import { PlanPrice } from '../../types';

const AVAILABLE_PLANS: Array<keyof typeof BillingPlans> = [
  'plan1',
  'plan19',
  'plan8',
  'plan18',
  'plan13',
  'plan20',
  'plan3',
  'plan17',
];

type PlanDetails = {
  [planType: string]: PlanPrice;
};

const planDetails: PlanDetails = {
  plan1: {
    billingPlan: 'plan1',
    hasAdditionalInfo: false,
    isGreen: false,
    planType: 'free',
    price: '',
  },
  plan13: {
    billingPlan: 'plan13',
    hasAdditionalInfo: false,
    isGreen: true,
    planType: 'greenFree',
    price: '',
  },
  plan17: {
    billingPlan: 'plan17',
    hasAdditionalInfo: true,
    isGreen: true,
    planType: 'greenExchange',
    price: '',
    promoPrice: '',
  },
  plan18: {
    billingPlan: 'plan18',
    hasAdditionalInfo: true,
    isGreen: false,
    planType: 'exchange',
    price: '',
    promoPrice: '',
  },
  plan19: {
    billingPlan: 'plan19',
    hasAdditionalInfo: false,
    isGreen: false,
    planType: 'standardPlan19',
    price: '',
  },
  plan20: {
    billingPlan: 'plan20',
    hasAdditionalInfo: false,
    isGreen: true,
    planType: 'greenStandardPlan20',
    price: '',
  },
  plan3: {
    billingPlan: 'plan3',
    hasAdditionalInfo: false,
    isGreen: true,
    planType: 'greenStandard',
    price: '',
  },
  plan8: {
    billingPlan: 'plan8',
    hasAdditionalInfo: false,
    isGreen: false,
    planType: 'standard',
    price: '',
  },
};

export const formatPrice = (price: number): string => {
  if (price === 0) {
    return '0';
  }

  const formattedPrice = Decimal.mul(price, 0.001).toString();

  if (formattedPrice.length < 5) {
    return `${formattedPrice}0`;
  }

  return formattedPrice;
};

// Find the price for a specific plan, if there is more than one price for the same plan,
// return them in plan prices in ascending order
const findPriceByPlanId = (planPrices: PlanPriceApi[], planId: string): PlanPriceApi[] => {
  return planPrices.filter((item) => item.planId === planId).sort((a, b) => a.price - b.price);
};

const mapPlanPrice = (planId: keyof typeof BillingPlans, planPrice: PlanPriceApi[]): PlanPrice | null => {
  const plan = planDetails[planId];

  if (!plan) {
    logger.error(`Plan ${planId} is not found in planDetails`);
    return null;
  }

  if ('promoPrice' in plan && planPrice.length !== 2) {
    logger.error(`Plan ${planId} has promo price but there is no promo price in planPrices`);
    return null;
  }

  const currentPlan: PlanPrice = {
    billingPlan: planId,
    hasAdditionalInfo: plan.hasAdditionalInfo,
    isGreen: plan.isGreen,
    planType: plan.planType,
    price: formatPrice(planPrice[0].price),
  };

  if ('promoPrice' in plan) {
    currentPlan.promoPrice = formatPrice(planPrice[1].price);
  }

  return currentPlan;
};

export const formatPlanPrices = (planPrices: PlanPriceApi[]): PlanPrice[] => {
  return AVAILABLE_PLANS.reduce<PlanPrice[]>((acc, planId) => {
    const planPrice = findPriceByPlanId(planPrices, BillingPlans[planId]);

    if (planPrice.length === 0) {
      return acc;
    }

    const currentPlan = mapPlanPrice(planId, planPrice);

    if (!currentPlan) {
      return acc;
    }

    return [...acc, currentPlan];
  }, []);
};
