'use client';

import { useFeatureIsOn } from 'feature-flag';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ContentLoader, Grid, LinkContainer, Typography } from 'ui';

import { useAuthUser } from '~/auth';
import { PlanCardList } from '~/plan/components/list';

import { Header } from './Header';
import { TokiClientCard } from './TokiClientCard';

export const Home = () => {
  const { isLoading, isAuthenticated } = useAuthUser();
  const isUserAuthenticated = isAuthenticated();
  const { t } = useTranslation();
  const { replace } = useRouter();
  const isOnboardingEnabled = useFeatureIsOn('onboarding');

  useEffect(() => {
    if (!isLoading && isUserAuthenticated) {
      replace('/invoices');
    }
  }, [isLoading, isUserAuthenticated, replace]);

  if (isLoading || isUserAuthenticated) {
    return <ContentLoader contentItemsCount={3} />;
  }

  return (
    <Box>
      <Box mb={4} px={2}>
        <Header />
      </Box>

      <Box pb={2}>
        <Grid container justifyContent="space-between" columnSpacing={{ lg: 4, xs: 2 }} rowSpacing={4} mb={2}>
          <Grid item xs={12} md={12}>
            <TokiClientCard />
          </Grid>

          {isOnboardingEnabled && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <PlanCardList baseUrl="/onboarding" />
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box display="flex" justifyContent="center">
                  <LinkContainer href="https://www.toki.bg/za-toki/planove#pricing-plans">
                    <Typography sx={{ textDecoration: 'underline' }}>{t('plans:comparePlans')}</Typography>
                  </LinkContainer>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
