import { useMemo } from 'react';

import { PlanPrice } from '../../types';
import { formatPlanPrices } from '../../utils/formatPlanPrices';
import { usePlanPrices } from '../usePlanPrices/usePlanPrices';

export const useAvailablePrices = (): PlanPrice[] => {
  const { data } = usePlanPrices();

  const plans = useMemo(() => {
    return formatPlanPrices(data.items);
  }, [data.items]);

  return plans;
};
