import { useQuery } from '@tanstack/react-query';

import { BaseHTTPResponse, createDataCacheKeys, Page, PlanPrice } from '~/data';
import { ResponseError, usePlanPriceApi } from '~/data/api';

const cacheKeys = createDataCacheKeys('planPrices');

export type UsePlanPricesResult = BaseHTTPResponse<Page<PlanPrice>>;

const emptyPage: Page<PlanPrice> = { items: [], page: 0, pages: 0, size: 0, total: 0 };

export const usePlanPrices = (): UsePlanPricesResult => {
  const api = usePlanPriceApi();

  const { data, isLoading, isError, isSuccess, error } = useQuery<Page<PlanPrice>, ResponseError>({
    queryFn: async () => {
      const response = await api.getAll();

      if (response.items === undefined) {
        return emptyPage;
      }

      return {
        items: response.items,
        page: 1,
        pages: 1,
        size: response.items.length,
        total: response.items.length,
      };
    },
    queryKey: [...cacheKeys.list()],
  });

  return {
    data: data ?? emptyPage,
    error,
    isError,
    isLoading,
    isSuccess,
  };
};
